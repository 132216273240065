<template>
	<div class="col-12 col-lg-11 col-xxl-10 text-center py-md-5">
		<img id="logo-pax" class=" my-4" :src="require(`GroomyRoot/assets/img/pax.svg`)">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-11 col-xl-9 col-xxl-8">
				<div class="row mt-lg-5">
					<div class="col-md-3"></div>
					<div class="col-6 col-md-3 mb-4">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('fr')">
							<span><span><img :src="require(`GroomyRoot/assets/img/flag-iso/FR.png`)"></span>Français</span>
						</a>
					</div>
					<!-- <div class="col-6 col-md-3 mb-4">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('de')">
							<span><span><img :src="require(`GroomyRoot/assets/img/flag-iso/DEU.png`)"></span>Deutsch</span>
						</a>
					</div> -->
					<div class="col-6 col-md-3 mb-4">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('en')">
							<span><span><img :src="require(`GroomyRoot/assets/img/flag-iso/EN.png`)"></span>English</span>
						</a>
					</div>
					<!-- <div class="col-6 col-md-3 mb-4">
						<a class="h-100 langSelect" href="#" @click.prevent="setLangue('es')">
							<span><span><img :src="require(`GroomyRoot/assets/img/flag-iso/ESP.png`)"></span>Español</span>
						</a>
					</div> -->
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Config from '@/mixins/Config.js'
	import { loadI18nPlugin } from 'GroomyRoot/assets/js/i18n.js'

	export default {
		name: 'ChoixLangue',
		mixins: [Config],

		methods: {
			setLangue(lang) {
				this.setConfig('lang', lang)
				loadI18nPlugin(lang)
				location.reload();
			}
		}
	}

</script>